@media print {
   body {
      font-size: 12pt;
   }

   img.picture {
      display: none;
   }

   .resume-section-content {
      color: black;
   }

   .resume-header {
      color: black;
      padding: 3rem;
   }

   .resume-header a {
      color: black;
      text-decoration: none;
   }

   .text-white {
      color: black !important;
   }

   .resume-wrapper {
      padding-top: 0;
      padding-bottom: 0;
   }
  
   .resume-wrapper-inner {
      max-width: none;
   }

   .resume-position-time {
      color: grey;
   }

   .resume-degree-time {
      color: grey;
   }

   .resume-timeline:before {
      content: '';
      display: block;
      border: 1px solid #328998;
   }

   .progress {
      position: relative;
   }

   .progress:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      border-bottom: 2rem solid #eeeeee;
   }
   
   .progress-bar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      border-bottom: 2rem solid #328998;
   }

   .resume-progress {
      height: 1rem;
   }

   .shadow-lg {
      box-shadow: none !important;
   }

   .badge-primary {
      color: black;
      background-color: white;
   }

   .badge-light {
      background-color: white;
   }

   footer {
      display: none;
   }

   .summary-section {
      break-after: always;
      page-break-after: always;
   }
}