/*!
 * Template Name: Pillar - Bootstrap 4 Resume/CV Template for Developers
 * Version: 1.0.1
 * Author: Xiaoying Riley
 * Copyright: 3rd Wave Media
 * Twitter: @3rdwave_themes
 * License: Creative Commons Attribution 3.0 License
 * Website: http://themes.3rdwavemedia.com/
*/

// @import "mixins";
@import "base";
// @import "responsive";
@import "print";
